import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulMissionAndVision {
        nodes {
          node_locale
          mainTitle {
            mainTitle
          }
          missionDescription {
            missionDescription
          }
          missionImage {
            file {
              url
            }
            gatsbyImageData
            title
          }
          missionImageSmall {
            file {
              url
            }
            gatsbyImageData
            title
          }
          missionLink
          missionLinkText
          missionTitle
          ourTeamDescription {
            ourTeamDescription
          }
          ourTeamImage1 {
            file {
              url
            }
            gatsbyImageData
            title
          }
          ourTeamImage1Small {
            file {
              url
            }
            gatsbyImageData
            title
          }
          ourTeamImage2 {
            file {
              url
            }
            gatsbyImageData
            title
          }
          ourTeamImage2Small {
            file {
              url
            }
            gatsbyImageData
            title
          }
          ourTeamTitle
          value1
          value2
          value3
          valuesDescription {
            valuesDescription
          }
          valuesTitle
          ilNostroTeamLinkText
          ilNostroTeamLink
        }
      }
    }
  `);
};

export default query;
