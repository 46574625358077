import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Mission.scss";
import CallToAction from "../CallToAction/CallToAction";
import arrowDown from "../../assets/images/hevelop/arrow-down.svg";
import query from "../Mission/query";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { filterDataByLanguage } from "../../Utils/Language";

const Mission = (props) => {
  const data = query();
  const dataMission = filterDataByLanguage(
    props.lang,
    data.allContentfulMissionAndVision
  );

  return (
    <div className="mission-container">
      <section className="intro-section">
        <Container fluid>
          <Row>
            <Col className="col-lg-9 col-12 mission-head-title">
              {dataMission.mainTitle.mainTitle}
            </Col>
            <Col className="col-lg-3 col-12 d-flex align-items-lg-end justify-content-lg-end justify-content-center arrow">
              <img className="mission-arrow-down" src={arrowDown} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mission-section">
        <Container fluid>
          <Row>
            <Col className="col-lg-6 col-12 mission-title d-flex align-items-start">
              {dataMission.missionTitle}
            </Col>
            <Col className="col-lg-6 col-12 mission-description">
              <p>{dataMission.missionDescription.missionDescription}</p>
              <CallToAction
                to={dataMission.missionLink}
                label={dataMission.missionLinkText}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {dataMission.missionImage && (
        <section className="hero-section">
          <ResponsiveImage
            className="image-cover"
            desktopImage={dataMission.missionImage}
            mobileImage={dataMission.missionImageSmall}
            alt={dataMission.missionImage.title}
          />
        </section>
      )}

      <section className="values-section">
        <Container fluid>
          <Row>
            <Col className="col-lg-6 col-12 values-title">
              {dataMission.valuesTitle}
            </Col>
            <Col className="col-lg-6 col-12 mission-description">
              <div className="values-list">
                <ul>
                  <li>{dataMission.value1}</li>
                  <li>{dataMission.value2}</li>
                  <li>{dataMission.value3}</li>
                </ul>
              </div>
              <div>
                <p>{dataMission.valuesDescription.valuesDescription}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="team-section">
        <Container fluid>
          <Row>
            <Col className="col-lg-6 col-12 team-title">
              {dataMission.ourTeamTitle}
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-6 col-12 team-description">
              <p>{dataMission.ourTeamDescription.ourTeamDescription}</p>
              <CallToAction
                to={dataMission.ilNostroTeamLink}
                label={dataMission.ilNostroTeamLinkText}
              />
            </Col>
          </Row>
          <div className="team-images">
            <div className="image-container left">
              <div className="spacer">
                <ResponsiveImage
                  desktopImage={dataMission.ourTeamImage1}
                  mobileImage={dataMission.ourTeamImage1Small}
                  alt={dataMission.ourTeamImage1.title}
                />
              </div>
            </div>
            <div className="image-container right">
              <div className="spacer">
                <ResponsiveImage
                  desktopImage={dataMission.ourTeamImage2}
                  mobileImage={dataMission.ourTeamImage2Small}
                  alt={dataMission.ourTeamImage2.title}
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

function ResponsiveImage(props) {
  const images = withArtDirection(getImage(props.desktopImage), [
    {
      media: "(max-width: 960px)",
      image: getImage(props.mobileImage),
    },
  ]);
  return (
    <GatsbyImage alt={props.alt} image={images} className={props.className} />
  );
}

ResponsiveImage.propTypes = {
  className: PropTypes.string,
  mobileImage: PropTypes.object.isRequired,
  desktopImage: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};

Mission.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Mission;
